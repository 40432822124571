import { registerApplication, start } from "single-spa";

const autenticado = false;

registerApplication({
  name: "@multiplica/login",
  app: () => System.import("@multiplica/login"),
  activeWhen: ["/login"],
});

registerApplication({
  name: "@multiplica/sidebarmenu",
  app: () => System.import("@multiplica/sidebarmenu"),
  activeWhen: ["/"],
});

registerApplication({
  name: "@multiplica/backoffice",
  app: () => System.import("@multiplica/backoffice"),
  activeWhen: ["/backoffice"],
});

registerApplication({
  name: "@multiplica/register",
  app: () => System.import("@multiplica/register"),
  activeWhen: ["/new-register"],
});

start({
  urlRerouteOnly: true,
});
